import { PublicKey } from "@solana/web3.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@project-serum/borsh";

export interface UserBetParamsFields {
  bet: types.BetFields;
}

export interface UserBetParamsJSON {
  bet: types.BetJSON;
}

export class UserBetParams {
  readonly bet: types.Bet;

  constructor(fields: UserBetParamsFields) {
    this.bet = new types.Bet({ ...fields.bet });
  }

  static layout(property?: string) {
    return borsh.struct([types.Bet.layout("bet")], property);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromDecoded(obj: any) {
    return new UserBetParams({
      bet: types.Bet.fromDecoded(obj.bet),
    });
  }

  static toEncodable(fields: UserBetParamsFields) {
    return {
      bet: types.Bet.toEncodable(fields.bet),
    };
  }

  toJSON(): UserBetParamsJSON {
    return {
      bet: this.bet.toJSON(),
    };
  }

  static fromJSON(obj: UserBetParamsJSON): UserBetParams {
    return new UserBetParams({
      bet: types.Bet.fromJSON(obj.bet),
    });
  }

  toEncodable() {
    return UserBetParams.toEncodable(this);
  }
}
