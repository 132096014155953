import { css } from '@emotion/react';
import { useConnectedWallet } from '@saberhq/use-solana';
import React, { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BN } from '@project-serum/anchor';
import { Bet } from '../../../api';
import { hooks, Store } from '../../../data';
import { setBetAmount, setStatus } from '../../../data/store/gameStateReducer';
import { betTypeStrToApi } from '../../../types';
import { RoulettePayouts } from '../../../util/roulette';
import { Wheel } from '../../../util/wheel';
import RouletteTable from './RouletteTable';
import UIButton from './UIButton';
import { boxShadow, colors } from '../../../styles/colors';
import { config } from '../../../config';
import { useWalletKit } from '@gokiprotocol/walletkit';

const wheelStyles = css`
  display: flex;
  align-items: center;
  margin-bottom: var(--padding);
`;

const cardStyles =  css`
  background: var(--gray);
  border-radius: var(--radius);
  ${boxShadow(`rgba(var(--gray-parts), 0.7)`)}
  padding: var(--padding);

  flex: 1;
`;

const cardTitleStyles = css`
  text-align: center;
  font-size: 1.3em;
  margin-bottom: 0.5em;
`;

const Roulette: React.FC = () => {
  const walletKit = useWalletKit();
  const wallet = useConnectedWallet();
  const wheel = useRef(null) as MutableRefObject<Wheel | null>;
  const betNumber = useSelector((s: Store) => s.gameState.betNumber);
  const betType = useSelector((s: Store) => s.gameState.betType);
  const sBetAmount = useSelector((s: Store) => s.gameState.betAmount);
  const solBalance = useSelector((s: Store) => s.gameState.userBalances.sol);
  const tokensBalance = useSelector((s: Store) => s.gameState.userBalances.tokens);
  const houseBalance = useSelector((s: Store) => s.gameState.houseBalance);
  const status = useSelector((s: Store) => s.gameState.status);
  const accountStatus = useSelector((s: Store) => s.gameState.accountStatus);
  const [createAccountProgress, setCreateAccountProgress] = useState(false);

  const dispatch = useDispatch();
  const api = hooks.useApi();

  const betAmount = +sBetAmount;
  const vrfFee = 0.002;
  const decimals = 9;
  const symbol = '🔸'; // '🍀';
  const nativeSymbol = '◎';
  const payoutRatio = RoulettePayouts[betType];
  const payout = payoutRatio * betAmount;
  const payoutFull = (payoutRatio + 1) * betAmount;
  const isValidBetAmount = Number.isFinite(betAmount) && typeof houseBalance === 'number' && typeof solBalance === 'number' && typeof tokensBalance === 'number' &&
      houseBalance >= payout && solBalance >= vrfFee && tokensBalance >= betAmount && betAmount > 0;

  const inputDisabled = status === 'betted' || status === 'signing';
  const playDisabled = inputDisabled || !isValidBetAmount;

  const getStatusText = () => {
    if(status === 'betted') {
      return 'Playing...';
    } else if(status === 'signing') {
      return 'Signing...';
    } else if(status === 'lost') {
      return <span style={{background: colors.red}}>You lose</span>;
    } else if(status === 'won') {
      return <span style={{background: colors.green}}>You win</span>;
    } else {
      return `Ready to play`;
    }
  };

  const canvasRefCallback = useCallback((canvas: HTMLCanvasElement | null) => {
    if(wheel.current) {
      wheel.current.destroy();
      wheel.current = null;
    }

    if(canvas) {
      wheel.current = new Wheel(canvas);
    }
  }, []);

  const play = useCallback(async () => {
    // todo: validate cluster
    const decimalsMul = 10 ** decimals;

    const bet = new Bet({
      amount: new BN(betAmount * decimalsMul),
      straightN: betNumber,
      betType: betTypeStrToApi(betType),
    });

    dispatch(setStatus('signing'));
    try {
      await api.play(bet, wheel.current);
    } finally {
      dispatch(setStatus('ready'));
    }
  }, [dispatch, api, betAmount, betType, betNumber]);

  const createAccount = useCallback(async () => {
    try {
      setCreateAccountProgress(true);
      await api.createAccount();
    } finally {
      setCreateAccountProgress(false);
    }
  }, [api]);

  return (
    <div css={css`display: flex; flex-direction: column;`}>
      <div className="wheel" css={wheelStyles}>
        <div>
          <canvas ref={canvasRefCallback} />
        </div>
        <div style={{marginLeft: '1em'}}>
          <RouletteTable
            betType={betType}
            straightN={betNumber}
          />
        </div>
      </div>
      <div css={css`display: flex; flex-direction: row;`}>
        <div css={cardStyles} style={{flex: '2'}}>
          <div css={cardTitleStyles}>⚙️ System info</div>

          <div>🌐 Cluster: {config.cluster}</div>
          <div>🎲 VRF fee: {vrfFee} {nativeSymbol}</div>
          { typeof houseBalance === 'number' && (
            <div>💰 House vault: {houseBalance} {symbol}</div>
          ) }
          <div css={css`margin-top: 0.5em`}>
            <a href="https://t.me/gr8den" target="_blank" rel="noreferrer">
              <UIButton>Support</UIButton>
            </a>
          </div>
        </div>
        <div css={cardStyles} style={{marginLeft: '0.8em', flex: '3'}}>
          <div css={cardTitleStyles}>🚀 Game</div>

          { wallet ? (
            <div>
              { accountStatus === 'inited' ? (
                <div>
                  <div>Balance for paying fees: {solBalance} {nativeSymbol}</div>
                  <div>Your balance: {tokensBalance || 0} {symbol}</div>
                  <div>Bet amount: <input style={{width: '88px'}} disabled={inputDisabled} value={sBetAmount} onChange={(e) => dispatch(setBetAmount(e.target.value))}/> {symbol}</div>
                  <div>Payout: x{payoutRatio + 1} = {payoutFull.toFixed(9)} {symbol}</div>
                  <div>{getStatusText()}</div>
                  <UIButton disabled={playDisabled} onClick={() => play()}>Play</UIButton>
                </div>
              ) : (accountStatus === 'unknown') ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <div>To play you need to create account</div>
                  <UIButton disabled={createAccountProgress} onClick={() => createAccount()}>Create account</UIButton>
                </div>
              ) }
            </div>
          ) : (
            <div
              className='text-center'
              onClick={() => walletKit.connect()}
              style={{
                cursor: 'pointer',
                // textDecoration: 'underline solid',
              }}
            >Connect wallet to play</div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default Roulette;
