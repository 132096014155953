import { css } from '@emotion/react';

const color = `var(--blue-parts)`;

const styles = css`
  position: absolute;
  box-sizing: content-box;
  height: 20px;
  width: 20px;
  top: calc(50% - 10px - 1px);
  left: calc(50% - 10px - 1px);

  background: rgba(${color}, 0.8);
  border: 1px solid rgba(${color}, 0.9);

  border-radius: 100%;
  transition: 0.2s linear opacity;
`;

const Chip: React.FC<{isBet: boolean}> = ({ isBet }) => {
  return (
    <span
      style={{opacity: isBet ? 1 : 0 }}
      css={styles}
    ></span>
  )
};

export default Chip;
