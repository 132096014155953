import { css } from '@emotion/react';
import { isRed as isNumberRed } from '../../../util/roulette';
import RouletteTableCell from './RouletteTableCell';
import { BetTypeString } from '../../../types';


const wrapperStyles = css`
  font-family: 'Avenir', sans-serif;
  color: white;
  font-weight: bold;

  .vertical {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  table {
    border-collapse: collapse;
    text-align: center;

    tr {
      height: 35px;
    }

    td {
      border: 2px solid white; //rgb(213, 233, 215);
      min-width: 30px;
      background: var(--green);
      cursor: pointer;
      transition: 0.2s linear background;

      &.red {
        background: var(--red);
      }
      &.black {
        background: var(--black);
      }
      &.green {
        background: var(--green);
      }

      &.no-border {
        cursor: default;
        border: none;
        background: unset;
      }
    }
  }
`;

interface Props {
  betType: BetTypeString;
  straightN: number;
}

const RouletteTable: React.FC<Props> = ({ betType, straightN }) => {
  const v = betType === 'straight' ? `straight_${straightN}` : betType;

  // set v(v: string) {
  //   if( v.startsWith('straight_') ) {
  //     const [s, sN] = v.split('_');
  //     this.$emit('update:betType', s);
  //     this.$emit('update:straightN', parseInt(sN));
  //   } else {
  //     this.$emit('update:betType', v);
  //   }
  // }

  const row1 = new Array(12).fill(0).map((_, idx) => (idx + 1) * 3);
  const row2 = new Array(12).fill(0).map((_, idx) => (idx + 1) * 3 - 1);
  const row3 = new Array(12).fill(0).map((_, idx) => (idx + 1) * 3 - 2);
  const isRed = new Array(37).fill(0).map((_, idx) => isNumberRed(idx));

  return (
    <div className="roulette-table" css={wrapperStyles}>
      <table>
        <tbody>
          <tr>
            <td className="no-border"></td>
            <RouletteTableCell value={v} colSpan={6} bet="1to18">1 to 18</RouletteTableCell>
            <RouletteTableCell value={v} colSpan={6} bet="19to36">19 to 36</RouletteTableCell>
          </tr>
          <tr>
            <RouletteTableCell value={v} className="green" rowSpan={2} bet="straight_37">00</RouletteTableCell>
            {
              row1.map(n => {
                return <RouletteTableCell
                  value={v}
                  bet={'straight_' + n}
                  className={isRed[n] ? 'red' : 'black'}
                  key={n}
                >{n}</RouletteTableCell>
              })
            }
          </tr>
          <tr>
            { row2.map(n => {
              return <RouletteTableCell
                value={v}
                bet={'straight_' + n}
                className={isRed[n] ? 'red' : 'black'}
                key={n}
              >{n}</RouletteTableCell>
            }) }
          </tr>
          <tr>
            <RouletteTableCell value={v} className="green" bet="straight_0" rowSpan={2}>0</RouletteTableCell>
            { row3.map(n => (
                <RouletteTableCell
                  value={v}
                  bet={'straight_' + n}
                  className={isRed[n] ? 'red' : 'black'}
                  key={n}
                >{n}</RouletteTableCell>
            )) }
          </tr>
          <tr>
            <RouletteTableCell value={v} bet="1to12" colSpan={4}>1 to 12</RouletteTableCell>
            <RouletteTableCell value={v} bet="13to24" colSpan={4}>13 to 24</RouletteTableCell>
            <RouletteTableCell value={v} bet="25to36" colSpan={4}>25 to 36</RouletteTableCell>
          </tr>
          <tr>
            <td className="no-border"></td>
            <RouletteTableCell value={v} colSpan={3} bet="even">Even</RouletteTableCell>
            <RouletteTableCell value={v} colSpan={3} bet="red" className="red">Red</RouletteTableCell>
            <RouletteTableCell value={v} colSpan={3} bet="black" className="black">Black</RouletteTableCell>
            <RouletteTableCell value={v} colSpan={3} bet="odd">Odd</RouletteTableCell>
          </tr>
        </tbody>
      </table>
    </div>
  )
};

export default RouletteTable;
