import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { setBetTypeFull } from '../../../data/store/gameStateReducer';
import Chip from './Chip';

interface Props {
  won?: string,
  value: string,
  bet: string,
  colSpan?: number,
  rowSpan?: number,
  onClick?: (value: string) => void,
  className?: string,
}


const RouletteTableCell: React.FC<PropsWithChildren<Props>> = ({
  children, value, bet, won,
  colSpan,
  rowSpan,
  onClick,
  className,
}) => {
  const dispatch = useDispatch();

  // const isWon = won === value;
  const isBet = bet === value;

  if(!className) {
    className = '';
  }
  className += ' roulette-table-cell';

  return (
    <td
      css={css`
        position: relative;
      `}
      className={className}
      colSpan={colSpan}
      rowSpan={rowSpan}
      onClick={() => dispatch(setBetTypeFull(bet))}
    >
      {children}

      <Chip isBet={isBet}/>
    </td>
  );
};

export default RouletteTableCell;
