import { PublicKey } from "@solana/web3.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@project-serum/borsh";

export interface StraightJSON {
  kind: "Straight";
}

export class Straight {
  static readonly discriminator = 0;
  static readonly kind = "Straight";
  readonly discriminator = 0;
  readonly kind = "Straight";

  toJSON(): StraightJSON {
    return {
      kind: "Straight",
    };
  }

  toEncodable() {
    return {
      Straight: {},
    };
  }
}

export interface FirstDozenJSON {
  kind: "FirstDozen";
}

export class FirstDozen {
  static readonly discriminator = 1;
  static readonly kind = "FirstDozen";
  readonly discriminator = 1;
  readonly kind = "FirstDozen";

  toJSON(): FirstDozenJSON {
    return {
      kind: "FirstDozen",
    };
  }

  toEncodable() {
    return {
      FirstDozen: {},
    };
  }
}

export interface SecondDozenJSON {
  kind: "SecondDozen";
}

export class SecondDozen {
  static readonly discriminator = 2;
  static readonly kind = "SecondDozen";
  readonly discriminator = 2;
  readonly kind = "SecondDozen";

  toJSON(): SecondDozenJSON {
    return {
      kind: "SecondDozen",
    };
  }

  toEncodable() {
    return {
      SecondDozen: {},
    };
  }
}

export interface ThirdDozenJSON {
  kind: "ThirdDozen";
}

export class ThirdDozen {
  static readonly discriminator = 3;
  static readonly kind = "ThirdDozen";
  readonly discriminator = 3;
  readonly kind = "ThirdDozen";

  toJSON(): ThirdDozenJSON {
    return {
      kind: "ThirdDozen",
    };
  }

  toEncodable() {
    return {
      ThirdDozen: {},
    };
  }
}

export interface OddJSON {
  kind: "Odd";
}

export class Odd {
  static readonly discriminator = 4;
  static readonly kind = "Odd";
  readonly discriminator = 4;
  readonly kind = "Odd";

  toJSON(): OddJSON {
    return {
      kind: "Odd",
    };
  }

  toEncodable() {
    return {
      Odd: {},
    };
  }
}

export interface EvenJSON {
  kind: "Even";
}

export class Even {
  static readonly discriminator = 5;
  static readonly kind = "Even";
  readonly discriminator = 5;
  readonly kind = "Even";

  toJSON(): EvenJSON {
    return {
      kind: "Even",
    };
  }

  toEncodable() {
    return {
      Even: {},
    };
  }
}

export interface RedJSON {
  kind: "Red";
}

export class Red {
  static readonly discriminator = 6;
  static readonly kind = "Red";
  readonly discriminator = 6;
  readonly kind = "Red";

  toJSON(): RedJSON {
    return {
      kind: "Red",
    };
  }

  toEncodable() {
    return {
      Red: {},
    };
  }
}

export interface BlackJSON {
  kind: "Black";
}

export class Black {
  static readonly discriminator = 7;
  static readonly kind = "Black";
  readonly discriminator = 7;
  readonly kind = "Black";

  toJSON(): BlackJSON {
    return {
      kind: "Black",
    };
  }

  toEncodable() {
    return {
      Black: {},
    };
  }
}

export interface FirstHalfJSON {
  kind: "FirstHalf";
}

export class FirstHalf {
  static readonly discriminator = 8;
  static readonly kind = "FirstHalf";
  readonly discriminator = 8;
  readonly kind = "FirstHalf";

  toJSON(): FirstHalfJSON {
    return {
      kind: "FirstHalf",
    };
  }

  toEncodable() {
    return {
      FirstHalf: {},
    };
  }
}

export interface SecondHalfJSON {
  kind: "SecondHalf";
}

export class SecondHalf {
  static readonly discriminator = 9;
  static readonly kind = "SecondHalf";
  readonly discriminator = 9;
  readonly kind = "SecondHalf";

  toJSON(): SecondHalfJSON {
    return {
      kind: "SecondHalf",
    };
  }

  toEncodable() {
    return {
      SecondHalf: {},
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromDecoded(obj: any): types.BetTypeKind {
  if (typeof obj !== "object") {
    throw new Error("Invalid enum object");
  }

  if ("Straight" in obj) {
    return new Straight();
  }
  if ("FirstDozen" in obj) {
    return new FirstDozen();
  }
  if ("SecondDozen" in obj) {
    return new SecondDozen();
  }
  if ("ThirdDozen" in obj) {
    return new ThirdDozen();
  }
  if ("Odd" in obj) {
    return new Odd();
  }
  if ("Even" in obj) {
    return new Even();
  }
  if ("Red" in obj) {
    return new Red();
  }
  if ("Black" in obj) {
    return new Black();
  }
  if ("FirstHalf" in obj) {
    return new FirstHalf();
  }
  if ("SecondHalf" in obj) {
    return new SecondHalf();
  }

  throw new Error("Invalid enum object");
}

export function fromJSON(obj: types.BetTypeJSON): types.BetTypeKind {
  switch (obj.kind) {
    case "Straight": {
      return new Straight();
    }
    case "FirstDozen": {
      return new FirstDozen();
    }
    case "SecondDozen": {
      return new SecondDozen();
    }
    case "ThirdDozen": {
      return new ThirdDozen();
    }
    case "Odd": {
      return new Odd();
    }
    case "Even": {
      return new Even();
    }
    case "Red": {
      return new Red();
    }
    case "Black": {
      return new Black();
    }
    case "FirstHalf": {
      return new FirstHalf();
    }
    case "SecondHalf": {
      return new SecondHalf();
    }
  }
}

export function layout(property?: string) {
  const ret = borsh.rustEnum([
    borsh.struct([], "Straight"),
    borsh.struct([], "FirstDozen"),
    borsh.struct([], "SecondDozen"),
    borsh.struct([], "ThirdDozen"),
    borsh.struct([], "Odd"),
    borsh.struct([], "Even"),
    borsh.struct([], "Red"),
    borsh.struct([], "Black"),
    borsh.struct([], "FirstHalf"),
    borsh.struct([], "SecondHalf"),
  ]);
  if (property !== undefined) {
    return ret.replicate(property);
  }
  return ret;
}
