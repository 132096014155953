import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { BetTypeString, GameStatus } from '../../types';

export type AccountStatus = 'unknown' | 'uninited' | 'inited';

interface Balances {
  sol?: number;
  tokens?: number;
}

// Define a type for the slice state
export interface GameState {
  status: GameStatus;
  userBalances: Balances;
  betAmount: string;
  betType: BetTypeString;
  betNumber: number;
  wonNumber: number;
  houseBalance?: number;
  accountStatus: AccountStatus,
}

/**
 * The initial {@linkcode GameState} to set in the data slice.
 */
const initialState: GameState = {
  status: 'ready',
  userBalances: {},
  betAmount: '0.1',
  betType: '13to24',
  betNumber: 7,
  wonNumber: 7,
  accountStatus: 'unknown',
};

/**
 * A data slice to control the Ecosystem feature.
 */
const gameStateSlice = createSlice({
  name: 'gameStateSlice',
  initialState: initialState,
  reducers: {
    setUserBalance: (state: GameState, action: PayloadAction<Balances | undefined>) => {
      if (action.payload) {
        // If ribs value changed, update.
        if (!_.isUndefined(action.payload.tokens)) state.userBalances.tokens = action.payload.tokens;
        // If sol value changed, update.
        if (!_.isUndefined(action.payload.sol)) state.userBalances.sol = action.payload.sol;
      } else {
        // Clear user balances.
        state.userBalances.sol = undefined;
        state.userBalances.tokens = undefined;
      }
    },
    setStatus: (state, action: PayloadAction<GameStatus>) => {
      state.status = action.payload;
    },
    setAccountStatus: (state, action: PayloadAction<AccountStatus>) => {
      state.accountStatus = action.payload;
    },
    setHouseBalance: (state, action: PayloadAction<number | undefined>) => {
      state.houseBalance = action.payload;
    },
    setBetAmount: (state, action: PayloadAction<string>) => {
      state.betAmount = action.payload;
    },
    setBetTypeFull: (state, action: PayloadAction<string>) => {
      const v = action.payload;
      if( v.startsWith('straight_') ) {
        const [s, sN] = v.split('_');
        state.betType = s as any;
        state.betNumber = parseInt(sN);
      } else {
        state.betType = v as any;
      }
    },
  },
});

export const {
  setUserBalance,
  setBetAmount,
  setBetTypeFull,
  setHouseBalance,
  setStatus,
  setAccountStatus,
} = gameStateSlice.actions;
export default gameStateSlice.reducer;
