import { RouletteWheelNumbers, getRouletteColor, NUMBER_00 } from './roulette';
import { colors } from '../styles/colors';

export class Wheel {
  // params
  font = 'Avenir';
  w = 400;
  dpr = window.devicePixelRatio || 1;
  spinDuration = 10 * 1000;

  // state
  ctx: CanvasRenderingContext2D;
  rotationStartAt: number = 0;
  elapsed: number = 0;
  rotPos = 0;
  drawAt = Date.now();
  ballRotationPos = 0;
  ballDropping = false;
  ballDropped = true;
  ballDroppingStartAt: number = 0;
  allowDrop = false;
  ballDropTarget = 7;
  requestFrameId: number | null = null;

  get h() {
    return this.w;
  }

  get canvasW() {
    return this.w * this.dpr;
  }
  get canvasH() {
    return this.h * this.dpr;
  }
  get style(): React.CSSProperties {
    return {
      width: this.w + 'px',
      height: this.h + 'px',
    }
  }

  constructor(canvas: HTMLCanvasElement) {
    canvas.width = this.canvasW;
    canvas.height = this.canvasH;
    Object.assign(canvas.style, this.style);

    this.ctx = canvas.getContext('2d') as any;
    this.draw();
  }

  destroy() {
    if(typeof this.requestFrameId === 'number') {
      cancelAnimationFrame(this.requestFrameId);
    }
  }

  spin() {
    this.rotationStartAt = Date.now();
    this.ballDropped = false;
    this.ballDropping = false;
    this.allowDrop = false;
  }
  drop() {
    this.allowDrop = true;
  }
  dropTo(wonNumber: number) {
    this.ballDropTarget = wonNumber;
    this.drop();
  }

  draw() {
    const ctx = this.ctx;
    let w = this.w;
    ctx.save();
    ctx.scale(this.dpr, this.dpr);
    ctx.clearRect(0, 0, this.w, this.h);

    const rotationDuration = this.spinDuration;
    const ballRadius = w * 0.015;
    const circleLineHeight = w * 0.1;
    const outRegion = circleLineHeight;
    const boldLineWidth = 4;
    let r = w / 2 - boldLineWidth / 2 - outRegion;
    let totR = w / 2 - boldLineWidth / 2;
    let c = w / 2;

    const drawDelta = Date.now() - this.drawAt;
    this.drawAt = Date.now();
    const elapsed = Date.now() - this.rotationStartAt;
    // this.elapsed += 1000 / 60;
    // const elapsed = this.elapsed;

    function calcSpeed(o: {minSpeed: number, maxSpeed: number, duration: number, t: number}) {
      const range = o.maxSpeed - o.minSpeed;
      return Math.max(o.minSpeed, range * (1 - Math.min(o.t, o.duration) / o.duration));
    }

    const rotationSpeed = calcSpeed({
      minSpeed: 0.0001,
      maxSpeed: 0.001,
      duration: rotationDuration,
      t: elapsed,
    });
    // console.log('rotationSpeed', rotationSpeed);
    this.rotPos += drawDelta * rotationSpeed;
    const angle = Math.PI * 2 * this.rotPos; //rotationSpeed * elapsed;
    // console.log('angle', angle);
    const numbers = RouletteWheelNumbers;

    const wheelPart = Math.PI * 2 / numbers.length;


    ctx.lineWidth = 2;
    ctx.translate(c, c);
    ctx.rotate(-angle);

    // out legion
    ctx.fillStyle = colors.wheel;
    ctx.save();
    ctx.beginPath();
    ctx.arc(0, 0, totR, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();
    ctx.restore();

    // wheel parts + text
    numbers.forEach((n, idx) => {
      const angleIdx = idx - 0.5;
      const fromAngle = angleIdx * wheelPart;
      const toAngle = (angleIdx + 1) * wheelPart;
      const centerAngle = (fromAngle + toAngle) / 2;

      ctx.save();
      ctx.fillStyle = getRouletteColor(n);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.arc(0, 0, r, fromAngle, toAngle);
      ctx.fill();
      ctx.closePath();

      ctx.save();
      ctx.fillStyle = 'white';
      ctx.textBaseline = 'middle';
      const fontSize = Math.round(r * 0.09);
      ctx.font = `bold ${fontSize}px ${this.font}`;
      ctx.textAlign = 'center';
      const tLineWidth = (r - circleLineHeight / 2 - boldLineWidth / 2);
      const tX = tLineWidth * Math.cos(centerAngle);
      const tY = tLineWidth * Math.sin(centerAngle);
      ctx.translate(tX, tY);
      ctx.rotate(centerAngle + Math.PI / 2);
      const text = n === NUMBER_00 ? '00' : '' + n;
      ctx.fillText(text, 0, 0);
      ctx.restore();

      ctx.restore();
    });

    // dividers
    numbers.forEach((n, idx) => {
      const angleIdx = idx - 0.5;
      const fromAngle = angleIdx * wheelPart;

      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = 'white';
      ctx.moveTo(0, 0);
      const lX = r * Math.cos(fromAngle);
      const lY = r * Math.sin(fromAngle);
      ctx.lineTo(lX, lY);
      ctx.closePath();
      ctx.stroke();
      ctx.restore();
    });

    ctx.fillStyle = colors.wheel;
    ctx.beginPath();
    ctx.arc(0, 0, r - circleLineHeight * 2, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.arc(0, 0, r - circleLineHeight, 0, Math.PI * 2);
    ctx.closePath();
    ctx.strokeStyle = 'white';
    ctx.stroke();

    ctx.lineWidth = boldLineWidth;
    ctx.beginPath();
    ctx.arc(0, 0, r - circleLineHeight * 2, 0, Math.PI * 2);
    ctx.closePath();
    ctx.strokeStyle = 'white';
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(0, 0, r, 0, Math.PI * 2);
    ctx.closePath();
    ctx.strokeStyle = 'white';
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(0, 0, totR, 0, Math.PI * 2);
    ctx.closePath();
    ctx.strokeStyle = colors.wheelStar2;
    ctx.stroke();

    ctx.lineWidth = 2;

    const starSizeBase = r * 0.05;
    const starSize = r * 0.30;

    function drawSpike(fill: boolean = false) {
      function getPos(n: number) {
        return [
          starSizeBase * Math.cos((Math.PI / 4) + Math.PI / 2 * n),
          starSizeBase * Math.sin((Math.PI / 4) + Math.PI / 2 * n),
        ];
      }

      ctx.save();
      ctx.translate(0, 0);
      ctx.beginPath();

      const size = starSize - starSizeBase / 2;
      const points = [
        getPos(0),
        [0, size],
        getPos(1),
        [-size, 0],
        getPos(2),
        [0, -size],
        getPos(3),
        [size, 0],
        getPos(0),
      ];

      for(let i = 1; i < points.length; i++) {
        const p2 = points[i];
        ctx.lineTo(p2[0], p2[1]);
      }
      ctx.closePath();

      if(fill) {
        ctx.fill();
      } else {
        ctx.stroke();
      }
      ctx.restore();
    }

    function drawStar() {
      ctx.fillStyle = colors.wheelStar;
      drawSpike(true);
      ctx.strokeStyle = colors.wheelStar2;
      drawSpike();

      function drawCircle(x: number, y: number) {
        ctx.beginPath();
        ctx.arc(x, y, starSizeBase / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }

      ctx.save();
      ctx.fillStyle = colors.wheelStar2;
      ctx.translate(0, 0);
      drawCircle(0, starSize);
      drawCircle(-starSize, 0);
      drawCircle(0, -starSize);
      drawCircle(starSize, 0);

      drawCircle(0, 0);

      ctx.restore();
    }

    drawStar();

    // ball
    const minBallRotationSpeed = 0.0004;
    const ballRotationSpeed = calcSpeed({
      minSpeed: minBallRotationSpeed,
      maxSpeed: 0.002,
      duration: rotationDuration,
      t: elapsed,
    });

    if(!this.ballDropping && ballRotationSpeed === minBallRotationSpeed && this.allowDrop) {
      this.ballDropping = true;
      this.ballDroppingStartAt = Date.now();
    }

    function calcRangeByTime(o: {from: number, to: number, duration: number, t: number}) {
      let { from , to, duration, t } = o;
      const range = to - from;
      if(t > duration) {
        t = duration;
      }
      return from + range * t / duration;
    }

    this.ballRotationPos += drawDelta * ballRotationSpeed;
    let ballRotationAngle = angle + (Math.PI * 2 * this.ballRotationPos);

    const ballTargetAngle = numberToAngle(this.ballDropTarget);
    let ballSpinPathR = totR - ballRadius - boldLineWidth - 1;
    const ballDropPathR = totR - circleLineHeight * 2 - ballRadius - 3;
    const ballZigZagFrom = ballSpinPathR - circleLineHeight + boldLineWidth + ballRadius;
    let ballPathR = ballSpinPathR;
    if(this.ballDropped) {
      ballPathR = ballDropPathR;
    } else {
      if(this.ballDropping) {
        let ballDroppingElapsed = Date.now() - this.ballDroppingStartAt;
        const ballDroppingDuration = 1 * 1000;
        ballPathR = calcRangeByTime({
          from: ballSpinPathR,
          to: ballDropPathR,
          duration: ballDroppingDuration,
          t: ballDroppingElapsed,
        });

        const dropFinished = ballPathR === ballDropPathR;
        const delta = Math.abs(ballTargetAngle - (ballRotationAngle % (Math.PI * 2)));
        const nearTarget = delta <= wheelPart / 2;
        if(dropFinished && nearTarget) {
          this.ballDropped = true;
        } else {
          if(ballPathR < ballZigZagFrom) {
            ballPathR += (Math.random() - 0.5) * ballRadius;
          }
        }
      }
    }

    function numberToAngle(n: number) {
      let idx = numbers.indexOf(n);
      return wheelPart * idx;
    }

    let ballAngle = this.ballDropped ? ballTargetAngle : ballRotationAngle;
    const ballX = ballPathR * Math.cos(ballAngle);
    const ballY = ballPathR * Math.sin(ballAngle);

    ctx.fillStyle = 'white';
    ctx.beginPath();
    ctx.arc(ballX, ballY, ballRadius, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();

    ctx.restore();

    this.requestFrameId = requestAnimationFrame(() => this.draw());
  }
}
