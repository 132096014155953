import { PublicKey } from "@solana/web3.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@project-serum/borsh";

export interface BetFields {
  amount: BN;
  straightN: number;
  betType: types.BetTypeKind;
}

export interface BetJSON {
  amount: string;
  straightN: number;
  betType: types.BetTypeJSON;
}

export class Bet {
  readonly amount: BN;
  readonly straightN: number;
  readonly betType: types.BetTypeKind;

  constructor(fields: BetFields) {
    this.amount = fields.amount;
    this.straightN = fields.straightN;
    this.betType = fields.betType;
  }

  static layout(property?: string) {
    return borsh.struct(
      [
        borsh.u64("amount"),
        borsh.u8("straightN"),
        types.BetType.layout("betType"),
      ],
      property
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromDecoded(obj: any) {
    return new Bet({
      amount: obj.amount,
      straightN: obj.straightN,
      betType: types.BetType.fromDecoded(obj.betType),
    });
  }

  static toEncodable(fields: BetFields) {
    return {
      amount: fields.amount,
      straightN: fields.straightN,
      betType: fields.betType.toEncodable(),
    };
  }

  toJSON(): BetJSON {
    return {
      amount: this.amount.toString(),
      straightN: this.straightN,
      betType: this.betType.toJSON(),
    };
  }

  static fromJSON(obj: BetJSON): Bet {
    return new Bet({
      amount: new BN(obj.amount),
      straightN: obj.straightN,
      betType: types.BetType.fromJSON(obj.betType),
    });
  }

  toEncodable() {
    return Bet.toEncodable(this);
  }
}
