import { PublicKey } from '@solana/web3.js';
import { Cluster } from './types';

// export const config = {
//   mint: new PublicKey('7EYQ3D9HEA4S2JGAMZi5xXtRPxLhtq15y2NebwCtqvY6'),
//   cluster: 'localnet' as Cluster,
// }

export const config = {
  mint: new PublicKey('HC3fCwLpZyS9Rs4JchmAnnJRmA9CyXegRtQ3dMCo2HvY'),
  cluster: 'devnet' as Cluster,
}
