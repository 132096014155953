import { ThunkDispatch as ReduxThunkDispatch } from '@reduxjs/toolkit';
import * as anchor from '@project-serum/anchor';
import { BetTypeKind, BetType as BetTypeApi } from '../api';
import { RoulettePayouts } from "../util/roulette";

export type ThunkDispatch = ReduxThunkDispatch<any, any, any>;
export type GameStatus = 'ready'| 'signing' | 'betted' | 'won' | 'lost';
export type Cluster = anchor.web3.Cluster | "localnet";

export interface HistoryItem {
  idx: number,
  // amount: BigNumber,
  // payout: BigNumber,
  date: Date,
  won: boolean,
  straightN: number,
  betType: BetTypeString,
  n: number,
  txid: string,
}

export type BetTypeString = keyof typeof RoulettePayouts;

export enum BetType {
  Straight, // idx:0
  FirstDozen, // 1-12, idx: 1
  SecondDozen, // 13-24, idx: 2
  ThirdDozen, // 25-36, idx: 3
  Odd, // idx: 4
  Even, // idx: 5
  Red, // idx: 6
  Black,
  FirstHalf, // 1-18
  SecondHalf
}

export interface Bet {
  betType: BetType;
  straightN: number;
}

export function betTypeStrToInt(s: BetTypeString): BetType {
  switch(s) {
    case 'straight':
      return BetType.Straight;
    case 'red':
      return BetType.Red;
    case 'odd':
      return BetType.Odd;
    case 'even':
      return BetType.Even;
    case 'black':
      return BetType.Black;
    case '1to18':
      return BetType.FirstHalf;
    case '19to36':
      return BetType.SecondHalf;
    case '1to12':
      return BetType.FirstDozen;
    case '13to24':
      return BetType.SecondDozen;
    case '25to36':
      return BetType.ThirdDozen;
    default:
      throw new Error('Invalid bet type');
  }
}

export function betTypeStrToApi(s: BetTypeString): BetTypeKind {
  switch(s) {
    case 'straight':
      return new BetTypeApi.Straight();
    case 'red':
      return new BetTypeApi.Red();
    case 'odd':
      return new BetTypeApi.Odd();
    case 'even':
      return new BetTypeApi.Even();
    case 'black':
      return new BetTypeApi.Black();
    case '1to18':
      return new BetTypeApi.FirstHalf();
    case '19to36':
      return new BetTypeApi.SecondHalf();
    case '1to12':
      return new BetTypeApi.FirstDozen();
    case '13to24':
      return new BetTypeApi.SecondDozen();
    case '25to36':
      return new BetTypeApi.ThirdDozen();
    default:
      throw new Error('Invalid bet type');
  }
}

export function betTypeIntToStr(s: BetType): BetTypeString {
  switch(s) {
    case BetType.Straight:
      return 'straight';
    case BetType.Red:
      return 'red';
    case BetType.Odd:
      return 'odd';
    case BetType.Even:
      return 'even';
    case BetType.Black:
      return 'black';
    case BetType.FirstHalf:
      return '1to18';
    case BetType.SecondHalf:
      return '19to36';
    case BetType.FirstDozen:
      return '1to12';
    case BetType.SecondDozen:
      return '13to24';
    case BetType.ThirdDozen:
      return '25to36';
    default:
      throw new Error('Invalid bet type');
  }
}

// export interface GameEvent extends Omit<Event, 'args'> {
//   args: {
//     amount: EthBigNumber,
//     bet: {
//       betType: BetType,
//       straightN: EthBigNumber,
//     },
//     n: EthBigNumber,
//     payout: EthBigNumber,
//     player: string,
//     won: boolean,
//     timestamp: EthBigNumber,
//   }
// }
