import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';
import { boxShadow } from '../../../styles/colors';

const styles = css`
  padding: 6px 8px;
  font-size: 1em;
  background: var(--blue);
  border-radius: var(--radius);
  border: none;
  ${boxShadow(`rgba(var(--blue-parts), 0.7)`)}
  color: white;
  cursor: pointer;
  transition: 0.2s linear all;
  outline: none;

  &:hover, &:disabled {
    background: var(--dark-blue);
    box-shadow: box-shadow( rgba(var(--dark-blue), 0.7) );
  }

  &:disabled {
    cursor: default;
  }

  /* &.green {
    background: $GREEN;
    box-shadow: box-shadow( rgba($GREEN, 0.7) );

    &:hover, &:disabled {
      background: $DARK_GREEN;
      box-shadow: box-shadow( rgba($DARK_GREEN, 0.7) );
    }
  }

  &.red {
    background: $RED;
    box-shadow: box-shadow( rgba($RED, 0.7) );

    &:hover, &:disabled {
      background: $DARK_RED;
      box-shadow: box-shadow( rgba($DARK_RED, 0.7) );
    }
  }

  &.gray {
    background: $GRAY2;
    box-shadow: box-shadow( rgba($GRAY2, 0.7) );

    &:hover, &:disabled {
      background: $GRAY;
      box-shadow: box-shadow( rgba($GRAY, 0.7) );
    }
  } */
`;

const UIButton: React.FC<PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>> = ({children, onClick, disabled}) => {
  return <button css={styles} disabled={disabled} onClick={onClick}>{children}</button>
};

export default UIButton;
