import { PublicKey } from "@solana/web3.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@project-serum/borsh";

export interface RoundFields {
  roundId: BN;
  status: types.RoundStatusKind;
  bet: types.BetFields;
  wonNumber: number;
  userWon: boolean;
  requestSlot: BN;
  requestTimestamp: BN;
  settleSlot: BN;
  settleTimestamp: BN;
}

export interface RoundJSON {
  roundId: string;
  status: types.RoundStatusJSON;
  bet: types.BetJSON;
  wonNumber: number;
  userWon: boolean;
  requestSlot: string;
  requestTimestamp: string;
  settleSlot: string;
  settleTimestamp: string;
}

export class Round {
  readonly roundId: BN;
  readonly status: types.RoundStatusKind;
  readonly bet: types.Bet;
  readonly wonNumber: number;
  readonly userWon: boolean;
  readonly requestSlot: BN;
  readonly requestTimestamp: BN;
  readonly settleSlot: BN;
  readonly settleTimestamp: BN;

  constructor(fields: RoundFields) {
    this.roundId = fields.roundId;
    this.status = fields.status;
    this.bet = new types.Bet({ ...fields.bet });
    this.wonNumber = fields.wonNumber;
    this.userWon = fields.userWon;
    this.requestSlot = fields.requestSlot;
    this.requestTimestamp = fields.requestTimestamp;
    this.settleSlot = fields.settleSlot;
    this.settleTimestamp = fields.settleTimestamp;
  }

  static layout(property?: string) {
    return borsh.struct(
      [
        borsh.u128("roundId"),
        types.RoundStatus.layout("status"),
        types.Bet.layout("bet"),
        borsh.u8("wonNumber"),
        borsh.bool("userWon"),
        borsh.u64("requestSlot"),
        borsh.i64("requestTimestamp"),
        borsh.u64("settleSlot"),
        borsh.i64("settleTimestamp"),
      ],
      property
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromDecoded(obj: any) {
    return new Round({
      roundId: obj.roundId,
      status: types.RoundStatus.fromDecoded(obj.status),
      bet: types.Bet.fromDecoded(obj.bet),
      wonNumber: obj.wonNumber,
      userWon: obj.userWon,
      requestSlot: obj.requestSlot,
      requestTimestamp: obj.requestTimestamp,
      settleSlot: obj.settleSlot,
      settleTimestamp: obj.settleTimestamp,
    });
  }

  static toEncodable(fields: RoundFields) {
    return {
      roundId: fields.roundId,
      status: fields.status.toEncodable(),
      bet: types.Bet.toEncodable(fields.bet),
      wonNumber: fields.wonNumber,
      userWon: fields.userWon,
      requestSlot: fields.requestSlot,
      requestTimestamp: fields.requestTimestamp,
      settleSlot: fields.settleSlot,
      settleTimestamp: fields.settleTimestamp,
    };
  }

  toJSON(): RoundJSON {
    return {
      roundId: this.roundId.toString(),
      status: this.status.toJSON(),
      bet: this.bet.toJSON(),
      wonNumber: this.wonNumber,
      userWon: this.userWon,
      requestSlot: this.requestSlot.toString(),
      requestTimestamp: this.requestTimestamp.toString(),
      settleSlot: this.settleSlot.toString(),
      settleTimestamp: this.settleTimestamp.toString(),
    };
  }

  static fromJSON(obj: RoundJSON): Round {
    return new Round({
      roundId: new BN(obj.roundId),
      status: types.RoundStatus.fromJSON(obj.status),
      bet: types.Bet.fromJSON(obj.bet),
      wonNumber: obj.wonNumber,
      userWon: obj.userWon,
      requestSlot: new BN(obj.requestSlot),
      requestTimestamp: new BN(obj.requestTimestamp),
      settleSlot: new BN(obj.settleSlot),
      settleTimestamp: new BN(obj.settleTimestamp),
    });
  }

  toEncodable() {
    return Round.toEncodable(this);
  }
}
