import { useConnectedWallet } from '@gokiprotocol/walletkit';
import * as anchor from '@project-serum/anchor';
import { ConnectedWallet } from '@saberhq/use-solana';
import * as spl from '@solana/spl-token';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import * as switchboard from '@switchboard-xyz/solana.js';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { hooks, Store, thunks } from '..';
import * as api from '../../api';
import { Bet, BetType, FlipProgram, User } from '../../api';
import { config } from '../../config';
import { Cluster, ThunkDispatch } from '../../types';
import { Severity } from '../../util/const';
import { Wheel } from '../../util/wheel';
import { GameState, setAccountStatus, setStatus } from '../store/gameStateReducer';

/*
 * Denominator for the ribs token
 */
const RIBS_PER_RACK = 1000000000;


const networkConfig: {
  cluster: Cluster,
} = {
  cluster: config.cluster,
}

const mint: PublicKey = config.mint;

enum ApiErrorType {
  General,
  AnchorError,
  GetFlipProgram,
  UserAccountMissing,
  SendTransactionError,
  WalletSignature,
  UnknownCommand,
  UnknownGameType,
  BadGuess,
  BadBet,
}

class ApiError extends Error {
  static general = (message: string) => new ApiError(ApiErrorType.General, message);
  static getFlipProgram = () => new ApiError(ApiErrorType.GetFlipProgram, `Couldn't get FlipProgram from the network.`);
  static userAccountMissing = () =>
    new ApiError(
      ApiErrorType.UserAccountMissing,
      "User hasn't created a flip account. Please enter the `user create` command."
    );
  static walletSignature = () => new ApiError(ApiErrorType.WalletSignature, `Couldn't retrieve user signature.`);
  static unknownCommand = (command: string) =>
    new ApiError(ApiErrorType.UnknownCommand, `Unknown command '${command}'`);
  static unknownGameType = () => new ApiError(ApiErrorType.UnknownGameType, `Unknown game type.`);
  static anchorError = (error: anchor.AnchorError) =>
    new ApiError(
      ApiErrorType.AnchorError,
      `[ANCHOR ERROR] ${error.error.errorCode.number}: ${error.error.errorMessage}`
    );
  static badGuess = (min: number, max: number) =>
    new ApiError(ApiErrorType.BadGuess, `[INVALID GUESS] Guess must be a number between ${min} and ${max}.`);
  static badBet = () =>
    new ApiError(ApiErrorType.BadBet, `[INVALID BET] Bet must be a number > 0 and less than your wallet balance.`);
  static sendTransactionError = (message: string) => new ApiError(ApiErrorType.SendTransactionError, message);

  readonly type: ApiErrorType;

  private constructor(type: ApiErrorType, message: string) {
    super(message);
    this.type = type;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

interface ApiInterface {
  /**
   * Handle command input from the user.
   */
  play: (bet: Bet, wheel: Wheel | null) => Promise<void>;
  createAccount: () => Promise<void>;
}

interface PrivateApiInterface extends ApiInterface {
  readonly dispose: () => Promise<void>;
}

class ApiState implements PrivateApiInterface {
  private readonly dispatch: ThunkDispatch;
  private readonly wallet: ConnectedWallet;
  private readonly cluster: Cluster;
  private readonly accountChangeListeners: number[] = [];
  private _program?: api.FlipProgram;
  private _user?: api.User;
  private _gameState?: GameState;
  private _wheel?: Wheel;

  constructor(wallet: ConnectedWallet, dispatch: ThunkDispatch) {
    this.wallet = wallet;
    this.dispatch = dispatch;
    this.cluster = networkConfig.cluster;

    // Upon instantiation of this object, try to fetch user account and balance asynchronously.
    this.user.catch((e) => this.handleError(e));

    console.log(`Connected as ${wallet.publicKey}`, Severity.Success);
  }

  /**
   * The rpc endpoint to be used.
   */
  get rpc(): string {
    // @TODO make rpc connection configurable.
    return api.defaultRpcForCluster(this.cluster);
  }

  /**
   * The currently known balance in the user's wallet.
   */
  get userRibsBalance(): number {
    return this._gameState?.userBalances?.tokens ?? 0;
  }

  /**
   * Try to return the cached program, and fallback on retrieving it from the network.
   *
   * If the program cannot be retrieved, an {@linkcode ApiError} will be thrown.
   */
  get program(): Promise<api.FlipProgram> {
    // If the program has already been set, return it.
    if (this._program) return Promise.resolve(this._program);

    return api
      .getFlipProgram(this.rpc)
      .then((program) => FlipProgram.load(program, mint))
      .then(
        (program) =>
          (this._program ??= (() => {
            // If there is not yet a known program, set it, log it, and return it.
            this.log(`Program retrieved for cluster: ${this.cluster}`);
            return program;
          })())
      )
      .catch((e) => {
        console.error(e);
        throw ApiError.getFlipProgram();
      });
  }

  /**
   * Try to return the cached user accounts, and fallback on retrieving them from the network.
   *
   * If the user does not have accounts set up, an {@linkcode ApiError} will be thrown.
   */
  get user(): Promise<api.User> {
    // If the user has already been set, return it.
    if (this._user) return Promise.resolve(this._user);

    return (async () => {
      const pubkey = this.wallet.publicKey;
      const program = await this.program;
      return api.User.load(program, pubkey, mint)
        .then(
          (user) =>
            (this._user ??= (() => {
              this.dispatch(setAccountStatus('inited'));

              // If there is not yet a known user, set it, log it, and return it.
              console.log(`Accounts retrieved for user: ${pubkey}`);
              this.watchUserAccounts();
              return user;
            })())
        )
        .catch((e) => {
          if (e instanceof ApiError) throw e;
          else {
            this.dispatch(setAccountStatus('uninited'));
            throw ApiError.userAccountMissing();
          }
        });
    })();
  }

  /**
   * Update the currently known {@linkcode GameState}.
   */
  public set gameState(gameState: GameState) {
    this._gameState = gameState;
  }

  /**
   * Teardown this {@linkcode ApiState} object.
   */
  public dispose = async () => {
    const program = await this.program;
    await (await this.user).unwatch();
    await Promise.allSettled(
      this.accountChangeListeners.map((id) => program.provider.connection.removeAccountChangeListener(id))
    );
  };

  public play = async (bet: Bet, wheel: Wheel | null) => {
    this._wheel = wheel || undefined;
    let user;
    try {
      user = await this.user; // Make sure that user is logged in and has accounts.
    } catch(err) {
      await this.createUserAccounts();
      user = await this.user;
    }

    const request = await user.placeBetReq(
      bet,
      this.wallet.publicKey
    );
    await this.packSignAndSubmit([request]);
  };

  public createAccount = async () => {
    try {
      await this.user;
    } catch(err) {
      await this.createUserAccounts();
    }
  };

  /**
   * Set up a user's VRF accounts (if they're not already set up).
   */
  private createUserAccounts = async () => {
    const user = await this.user.catch(() => undefined);
    // If there are already known user accounts, do not set up new accounts.
    if (user) return;

    // Gather necessary programs.
    const program = await this.program;
    const anchorProvider = new anchor.AnchorProvider(program.provider.connection, this.wallet, {});

    console.log(`Checking if user needs airdrop...`);
    api.verifyPayerBalance(program.provider.connection, anchorProvider.publicKey);

    // If there are no known user accounts, begin accounts set up.
    console.log(`Building user accounts...`);

    // Build out and sign transactions.
    const request = await api.User.createReq(program, anchorProvider.wallet.publicKey);
    console.log('sending...', anchorProvider.wallet.publicKey.toBase58());
    await this.packSignAndSubmit(request[0]);
    console.log('sent');

    // Try to load the new user accounts.
    await this.user;
  };

  /**
   * Attempt to airdrop to the user
   */
  // private userAirdrop = async () => {
  //   // User needs to be logged in and have accounts.
  //   const user = await this.user;

  //   // Build out and sign transactions.
  //   this.log(`Building airdrop request...`);
  //   const request = await user.airdropReq(this.wallet.publicKey);
  //   await this.packSignAndSubmit([request]);

  //   await this.playPrompt();
  // };

  private packSignAndSubmit = async (transactions: switchboard.TransactionObject[], skipPreflight = false) => {
    const program = await this.program;
    const packed = switchboard.TransactionObject.pack(transactions);

    // Sign transactions.
    this.log(`Requesting user signature...`);

    const latestBlockhash = await program.provider.connection.getLatestBlockhash('confirmed');
    const signed = await this.wallet
      .signAllTransactions(
        packed.map((object) => {
          const txn = object.toTxn(latestBlockhash);
          if (object.signers.length) txn.partialSign(...object.signers);
          return txn;
        })
      )
      .then((signed) => {
        this.log(`Awaiting network confirmation...`);
        return signed;
      })
      .catch((e) => {
        console.error(e);
        throw ApiError.walletSignature();
      });

    // Submit transactions and await confirmation
    for (const tx of signed) {
      await program.provider.connection
        .sendRawTransaction(tx.serialize(), { skipPreflight, maxRetries: 10 })
        .then((sig) => program.provider.connection.confirmTransaction(sig))
        .catch((e) => {
          if (e instanceof anchor.web3.SendTransactionError) {
            const anchorError = e.logs ? anchor.AnchorError.parse(e.logs) : null;
            if (anchorError) {
              console.error(anchorError);
              throw ApiError.anchorError(anchorError);
            } else {
              console.error(e);
              throw ApiError.sendTransactionError(e.message);
            }
          } else {
            console.error(e);
            throw ApiError.general('An error occurred while sending transaction.');
          }
        });
    }
  };

  /**
   * Fetches the user's current SOL balance.
   */
  private watchUserAccounts = async () => {
    const onSolAccountChange = (account: anchor.web3.AccountInfo<Buffer> | null) => {
      this.dispatch(thunks.setUserBalance({ sol: account ? account.lamports / LAMPORTS_PER_SOL : undefined }));
    };
    const onRibsAccountChange = (account: anchor.web3.AccountInfo<Buffer> | null) => {
      if (!account) return;
      const rawAccount = spl.AccountLayout.decode(account.data);
      this.dispatch(
        thunks.setUserBalance({
          tokens: rawAccount.amount ? Number(rawAccount.amount) / RIBS_PER_RACK : undefined,
        })
      );
    };
    const onHouseAccountChange = (account: anchor.web3.AccountInfo<Buffer> | null) => {
      if (!account) return;
      const rawAccount = spl.AccountLayout.decode(account.data);
      this.dispatch(
        thunks.setHouseBalance(
          rawAccount.amount ? Number(rawAccount.amount) / RIBS_PER_RACK : undefined
        )
      );
    };

    // Grab initial values.
    const program = await this.program;
    const user = await this.user;
    await program.provider.connection.getAccountInfo(this.wallet.publicKey).then(onSolAccountChange);
    await program.provider.connection.getAccountInfo(user.state.rewardAddress).then(onRibsAccountChange);
    await program.provider.connection.getAccountInfo(program.house.state.houseVault).then(onHouseAccountChange);

    // Listen for account changes.
    this.accountChangeListeners.push(
      ...[
        program.provider.connection.onAccountChange(this.wallet.publicKey, onSolAccountChange),
        program.provider.connection.onAccountChange(user.state.rewardAddress, onRibsAccountChange),
        program.provider.connection.onAccountChange(program.house.state.houseVault, onHouseAccountChange),
      ]
    );


    // Watch user object
    user.watch({
      betPlaced: async (event) => {
        this.dispatch(setStatus('betted'));
        this._wheel?.spin();
      },
      betSettled: async (event) => {
        this.dispatch(setStatus(event.userWon ? 'won' : 'lost'));
        this._wheel?.dropTo(event.wonNumber);
      }
    });

    return user;
  };

  /**
   * Handles errors that are thrown.
   */
  private handleError = (e: any) => {
    if (e instanceof ApiError) {
      this.log(e.message, Severity.Error);
      // After an unknown command, try to prompt the user to play.
      // if (e.type !== ApiErrorType.UserAccountMissing) this.playPrompt();
    } else console.error('ApiProvider[handleError] Error occurred:\n', e);
  };

  /**
   * Log to DisplayLogger.
   */
  private log = (message: string, severity: Severity = Severity.Normal) =>
    this.dispatch(thunks.log({ message, severity }));
}

/**
 * The variant of {@linkcode ApiInterface} that is provided when no user is logged in.
 */
class NoUserApiState implements PrivateApiInterface {
  private readonly dispatch?: ThunkDispatch;

  constructor(dispatch?: ThunkDispatch) {
    this.dispatch = dispatch;
    this.log();
    if (this.dispatch) this.dispatch(thunks.setUserBalance());
  }

  public handleCommand = async () => this.log();

  public dispose = async () => {};
  public play = async () => {};
  public createAccount = async () => {};

  /**
   * Log to DisplayLogger.
   */
  private log = () => {
    if (this.dispatch) this.dispatch(thunks.log({ message: 'No wallet is connected.' }));
  };
}

const ApiContext = React.createContext<ApiInterface>(new NoUserApiState());
const useApi = () => React.useContext(ApiContext);

/**
 * Exposes the API functionality to other parts of the applications.
 *
 * Will provide {@linkcode ApiContext} to any child components by calling `const api = useApi();`
 */
export const ApiProvider: React.FC<React.PropsWithChildren> = (props) => {
  const dispatch = hooks.useThunkDispatch();
  const wallet = useConnectedWallet();
  const gameState = useSelector((store: Store) => store.gameState);
  const [stateWallet, setStateWallet] = React.useState(wallet);

  // The api is rebuilt only when the connected pubkey changes
  const api = React.useMemo(
    () => (stateWallet ? new ApiState(stateWallet, dispatch) : new NoUserApiState(dispatch)),
    [stateWallet, dispatch]
  );

  // If a new wallet has been set, dispose of the old api object and set the new wallet state.
  React.useEffect(() => {
    if (wallet !== stateWallet) api.dispose().then(() => setStateWallet(wallet));
  }, [api, wallet, stateWallet]);

  React.useEffect(() => {
    if (api instanceof ApiState) api.gameState = gameState;
  }, [api, gameState]);

  return <ApiContext.Provider value={api} children={props.children} />;
};

/**
 * Expose {@linkcode ApiContext} to the children
 */
export default useApi;
