export const colors = {
  red: '#C93E30',
  black: '#363738',
  green: '#76AC47',
  gray2: '#474849',

  wheel: '#363738',
  wheelStar: '#363738',
  wheelStar2: 'white',
};

export function boxShadow(color: any): string {
  return `box-shadow: 0 3px 0px 0px ${color};`
}
