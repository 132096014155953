import { colors } from '../styles/colors';

export function isOdd(n: number) {
  return (n % 2) === 1;
}

export function isEven(n: number) {
  return (n % 2) === 0;
}

// for n > 0
export function isRed(n: number) {
  if( ((n >= 1) && (n <= 10)) || ((n >= 19) && (n <= 28)) ) {
    return isOdd(n);
  } else { // from 11 to 18 and 29 to 36
    return isEven(n);
  }
}

// for n > 0
export function isBlack(n: number) {
  return !isRed(n);
}

export function getRouletteColor(n: number) {
  if(n <= 0) {
    return colors.green;
  } else if( isRed(n) ) {
    return colors.red;
  } else {
    return colors.black;
  }
}

export const RoulettePayouts = {
  straight: 35,
  odd: 1,
  even: 1,
  red: 1,
  black: 1,
  '1to12': 2,
  '13to24': 2,
  '25to36': 2,
  '1to18': 1,
  '19to36': 1,
};

export const NUMBER_00 = 37;

export const RouletteWheelNumbers = [0, 28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, NUMBER_00, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2];
